import React from 'react'
import { useState , useEffect } from 'react';
import { Navbar, Container } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import logo from '../img/logo.png'
import uberIcon from '../img/uber-icon.svg'
import doordashIcon from '../img/doordash-icon.svg'
import { BrowserRouter as Router } from "react-router-dom";

export const Nav_bar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled,setSrolled] = useState(false)

    useEffect(() => {
        const onScroll = () => {
            if(window.scrollY > 50) {
                setSrolled(true)
            }
            else {
                setSrolled(false)
            }
        }
        
        window.addEventListener("scroll", onScroll)

        return ()=> window.addEventListener("scroll", onScroll)
    })

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
      }

    return (
        <Router>
            <Navbar expand='lg' className={scrolled ? "scrolled" : ""}>
                <Container>
                <Navbar.Brand href="#home">
                    <img src={logo} alt='logo'></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                    <Nav.Link href="#aboutMe" className={activeLink === 'aboutMe' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('aboutMe')}>About Us</Nav.Link>
                    <Nav.Link href="#pop" className={activeLink === 'pop' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('pop')}>Heat</Nav.Link>
                    <Nav.Link href="#menu" className={activeLink === 'menu' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('menu')}>Menu</Nav.Link>
                    </Nav>
                    <span className='navbar-text'>
                        <div className='social-icon'>
                            <a href='https://www.ubereats.com/ca/store/parkside-cafe-%26-sushi/cOBnrYxMXJi84hDWeIfquw?diningMode=DELIVERY&ps=1&sc=SEARCH_SUGGESTION'><img src={uberIcon} alt=''></img></a>
                            <a href='https://www.doordash.com/en-CA/store/parkside-caf%C3%A9-&-sushi-calgary-24954333/'><img src={doordashIcon} alt=''></img></a>
                        </div>
                        <button className='vvd' onClick={()=>{window.location.href ='https://parkside-cafe-sushi.square.site/s/order#9'}}>Order Now!</button>
                    </span>
                </Navbar.Collapse>
                </Container>
            </Navbar>
        </Router>
        
    )
}