import "bootstrap/dist/css/bootstrap.min.css"
import { Nav_bar } from "./Components/Nav";
import './App.css'
import Banner from "./Components/Banner";
import ShowHeat from "./Components/ShowHeat";
import About from "./Components/About";
import Menu from "./Components/Menu";
import Footer from "./Components/Footer";

function App() {
  return (
    <div className="App">
        <Nav_bar />
        <Banner />
        <About />
        <ShowHeat />
        <Menu />
        <Footer />
    </div>
  );
}

export default App;
