import React from 'react'
import uberIcon from '../img/uber-icon.svg'
import doordashIcon from '../img/doordash-icon.svg'

export default function Footer() {
    return (
        <div className='footer'>
            <div className='footer_box1'>
                <p>Hours: Monday to Saturday: 11am ~ 9pm</p>
                <p>Phone: 403-719-9888</p>
                <p>Email: parksidecafeyyc@outlook.com</p>
            </div>
            <div className='social-icon'>
                <a href='https://www.doordash.com/en-CA/store/parkside-caf%C3%A9-&-sushi-calgary-24954333/'><img src={doordashIcon} alt=''></img></a>
                <a href='https://www.ubereats.com/ca/store/parkside-cafe-%26-sushi/cOBnrYxMXJi84hDWeIfquw?diningMode=DELIVERY&ps=1&sc=SEARCH_SUGGESTION'><img src={uberIcon} alt=''></img></a>
             </div>
        </div>
    )
}
