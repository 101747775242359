import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import img1 from '../img/ps1.jpeg'
import img2 from '../img/ps2.jpeg'
import img3 from '../img/ps3.jpeg'
import img4 from '../img/ps4.jpeg'
import img5 from '../img/ps5.jpeg'
import img6 from '../img/ps6.jpeg'
import img7 from '../img/ps7.jpeg'
import ls1 from '../img/pokeBowl.jpeg'
import ls2 from '../img/curryChicken.webp'
import ls3 from '../img/gdNoodle.jpeg'
import ls4 from '../img/terriyakiBeef.jpeg'
import ls5 from '../img/singFriedNoodle.jpeg'
import ls6 from '../img/friedRice.jpeg'



export default function ShowHeat() {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 2000 },
          items: 3
        },
        desktop: {
            breakpoint: { max: 2000, min: 1050 },
            items: 2
        },
        tablet: {
          breakpoint: { max: 1050, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    return (
        <div className='showHeat' id='pop'>
            <div className='heat_tit'>
                <TrackVisibility>
                    {({ isVisible }) =>
                        <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>  
                                <h1>Parkside Special</h1>
                        </div>}
                </TrackVisibility>
            </div>
            <Carousel responsive={responsive} infinite={true} className="heat_slider" swipeable="true" draggable="true" autoPlay="true">
                <div className='heat_img'>
                    <img src={img1}></img>
                    <h1>Parkside Roll</h1>
                </div>
                <div className='heat_img'>
                    <img src={img2}></img>
                    <h1>Japa Fries</h1>
                </div>
                <div className='heat_img'>
                    <img src={img3}></img>
                    <h1>Sashimi</h1>
                </div>
                <div className='heat_img'>
                    <img src={img4}></img>
                    <h1>Shrimp Tempura</h1>
                </div>
                <div className='heat_img'>
                    <img src={img5}></img>
                    <h1>Parkside Coffee</h1>
                </div>
                <div className='heat_img'>
                    <img src={img6}></img>
                    <h1>Parkside</h1>
                </div>
                <div className='heat_img'>
                    <img src={img7}></img>
                    <h1>Delux Tray</h1>
                </div>
            </Carousel>
            <div className='lunch_tit'>
                <TrackVisibility>
                    {({ isVisible }) =>
                        <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>  
                                <h1>Lunch Special</h1>
                        </div>}
                </TrackVisibility>
            </div>
            <Carousel responsive={responsive} infinite={true} className="heat_slider" swipeable="true" draggable="true" autoPlay='true'>
                <div className='heat_img'>
                    <img src={ls1}></img>
                    <h1>Parkside Poke Bowl</h1>
                </div>
                <div className='heat_img'>
                    <img src={ls2}></img>
                    <h1>Curry Chicken Rice</h1>
                </div>
                {/* <div className='heat_img'>
                    <img src={ls3}></img>
                    <h1>Guangdong Fried Noodle</h1>
                </div> */}
                <div className='heat_img'>
                    <img src={ls4}></img>
                    <h1>Teriyaki Beef Rice</h1>
                </div>
                {/* <div className='heat_img'>
                    <img src={ls5}></img>
                    <h1>Singapore Fried Noodle</h1>
                </div>
                <div className='heat_img'>
                    <img src={ls6}></img>
                    <h1>Special Fried Rice</h1>
                </div> */}
            </Carousel>
        </div>
    )
}
