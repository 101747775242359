import React from 'react'
import TrackVisibility from 'react-on-screen';
import { TypeAnimation } from 'react-type-animation';
import 'animate.css';

export default function About() {
    return (
        <div className='aboutMe' id='aboutMe'> 
            <div className='aboutMe_tit'>
                <TrackVisibility>
                    {({ isVisible }) =>
                        <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>  
                            <h1>About Us</h1>
                            <br /> <br/>
                            <h2>
                                Welcome to Parkside Cafe and Sushi !
                            </h2>
                            <br />
                            <p>We are located in 602 12 Ave. SW Calgary, AB T2R</p>
                            <p>Hours: Monday to Saturday: 11am ~ 9pm</p>
                            <p>Phone: 403-719-9888</p>
                            <p>Email: parksidecafeyyc@outlook.com</p>
                        </div>}
                </TrackVisibility>
                <TypeAnimation sequence={[
                        'See you in Parkside!', 2000,
                        'Thank you!',2000,
                    ]} speed={30} repeat={Infinity} className='greeting'>
                </TypeAnimation>
            </div>  
        </div>
    )
}
