import React from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Container, Row, Col } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
import 'animate.css';

export default function Banner() {
    return (
        <div className='banner' id='home'>
            <Container>
                <Row className='greeting'>
                    <Col >
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? 'animate__animated animate__fadeIn' : 'notShowGreeting'}>
                                    <h1>Parkside</h1>
                                    <h2>Cafe and Sushi</h2> 
                                </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
