import React, { useEffect } from 'react'
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import menu1 from '../img/menu1.jpeg'
import menu2 from '../img/menu2.jpeg'

import { useState } from 'react';

export default function Menu() {
    const [ dispState1, setDispState1 ] = useState('none')
    const [ dispState2, setDispState2 ] = useState('none')

    const showImg1Preview = () => {
        setDispState1('block')
    }

    const showImg2Preview = () => {
        setDispState2('block')
    }

    const closeImgPreview = () => {
        if(dispState1 === 'block') {
            setDispState1('none')
        }
        else {
            setDispState2('none')
        }
    }

    useEffect(() => {
        document.documentElement.style.setProperty("--menu1-dis", dispState1)
        document.documentElement.style.setProperty("--menu2-dis", dispState2)
    }, [ dispState1, dispState2])

    return (
        <div className='menu' id='menu'>
            <TrackVisibility>
                {({ isVisible }) =>
                    <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}> 
                        <h1>Menu</h1> 
                    </div>
                }       
            </TrackVisibility>
            <div className='menu-container'>
                <div className='menu-box'>
                    <img src={menu1} onClick={showImg1Preview}></img>
                </div>
                <div className='menu-box'>
                    <img src={menu2} onClick={showImg2Preview}></img>
                </div>
            </div>
            <div className='menu1-preview' onClick={closeImgPreview}>
                <img src={menu1}></img>
            </div>
            <div className='menu2-preview' onClick={closeImgPreview}>
                <img src={menu2}></img>
            </div>
        </div>
    )
}
